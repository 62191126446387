import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  WithStore
} from 'pure-react-carousel'
import { Fragment, Ref, RefObject, forwardRef, useEffect, useRef, useState } from 'react'

import CourseCard from 'components/cards/Content/CourseCard'

import {
  CclCourseCourseCardPartsFragment,
  ProgramBookmarkPartsFragment,
  SavedArtifactsAndGuidesForUserQuery,
  useCoursesListQuery
} from 'gql'

import useContainerQuery from 'hooks/useContainerQuery'
import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'

import { ReactComponent as ChevronLeftIcon } from 'images/icon--thin-chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/icon--thin-chevron-right.svg'

// Standard container breakpoints
const ONE_SLIDE_MAX_WIDTH = 592 // px
const THREE_SLIDE_MIN_WIDTH = 920 // px

interface SwimlaneCarouselProps {
  savedData?: SavedArtifactsAndGuidesForUserQuery
  pageLocation: string
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

interface UpcomingLiveCoursesCarouselState {
  currentSlide: number
  totalSlides: number
  visibleSlides: number
}

export const UpcomingLiveCoursesCarousel = ({ pageLocation }: SwimlaneCarouselProps) => {
  const { data, loading, error } = useCoursesListQuery({
    variables: {
      availability: 'live',
      sortOrder: 'start_date'
    }
  })
  const courses = data?.cclCourses ?? []

  const [params, containerRef] = useContainerQuery({
    isMobileView: {
      maxWidth: ONE_SLIDE_MAX_WIDTH
    },
    threeSlides: {
      minWidth: THREE_SLIDE_MIN_WIDTH
    }
  })

  const slideCount = useCarouselSlidesCount(params)

  if (loading || !data) return null
  if (error) return null

  return (
    <CarouselProvider
      naturalSlideWidth={400}
      naturalSlideHeight={400}
      totalSlides={params.isMobileView ? courses.length + 1 : courses.length} // +1 for the empty element
      visibleSlides={slideCount}
      isIntrinsicHeight={true}
      className="w-full"
    >
      <CarouselWithStore
        containerRef={containerRef}
        visibleSlides={slideCount}
        minCardWidth={280}
        courses={courses}
        pageLocation={pageLocation}
      />
    </CarouselProvider>
  )
}

interface CarouselProps {
  containerRef: Ref<HTMLDivElement>
  visibleSlides: number
  courses: CclCourseCourseCardPartsFragment[]
  pageLocation: string
  minCardWidth?: number
}

const BUTTON_STYLINGS = 'text-rb-black flex items-center justify-center'

const Carousel = forwardRef(
  (
    {
      containerRef,
      courses,
      pageLocation,
      visibleSlides,
      currentSlide,
      minCardWidth = 272
    }: CarouselProps & UpcomingLiveCoursesCarouselState,
    // Required otherwise there will be an error in console.
    ref: RefObject<HTMLDivElement> // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    const { isLoggedIn } = useCurrentUser()
    const carouselRef = useRef<HTMLDivElement>(null) // cannot use containerRef. It will always be undefined in this component
    const [slideWidth, setSlideWidth] = useState(0)
    const isMobileView = visibleSlides === 1
    const gapWidth = 16
    const containerWidth = document.body.offsetWidth - 16 * 2 // remove the left/right content padding - only used on mobile view
    const totalSlidesWidth = slideWidth * visibleSlides
    const totalGapWidth = gapWidth * visibleSlides
    const sliderWidth = totalSlidesWidth + totalGapWidth
    const emptyElementWidth = isMobileView
      ? slideWidth - Math.abs(containerWidth - sliderWidth) + 2 * gapWidth
      : 0
    const isLastSlideVisible = courses.length - visibleSlides === currentSlide
    const isFirstSlideVisible = currentSlide === 0

    useEffect(() => {
      document.body.classList.add('overscroll-x-none')

      return () => {
        document.body.classList.remove('overscroll-x-none')
      }
    }, [])

    useEffect(() => {
      const container = carouselRef.current
      const onResize = () => {
        if (isMobileView) {
          const containerWidth =
            (container?.offsetWidth ?? document.body.offsetWidth) - 16 * 2
          const newSlideWidth = Math.max(
            containerWidth * 0.1 + gapWidth,
            minCardWidth + gapWidth
          )
          setSlideWidth(newSlideWidth)
        }
      }
      window.addEventListener('resize', onResize)

      onResize()

      return () => {
        window.removeEventListener('resize', onResize)
      }
    }, [isMobileView, minCardWidth])

    return (
      <div ref={containerRef} className={cn(isLoggedIn ? 'pt-3' : 'pt-[80px]')}>
        <div className="flex flex-col gap-6">
          <div className="@container relative w-[calc(100%+1rem)] overflow-x-hidden min-w-0">
            <div
              className={cn(
                'flex items-center justify-between',
                isLoggedIn ? 'mb-4' : 'mb-8'
              )}
            >
              <div className="flex flex-col">
                {isLoggedIn ? (
                  <h3 className="text-rb-gray-400 text-xl leading-[1.4] font-semibold mb-0 font-sans">
                    Featured live courses
                  </h3>
                ) : (
                  <>
                    <h3 className="text-rb-gray-400 text-[28px] leading-[1.2] font-medium mb-2 font-sans">
                      Upcoming live courses
                    </h3>
                    <p className="text-rb-gray-400 text-xl leading-[1.4] font-medium mb-0">
                      Engage with top tech experts in live sessions and real-time insights
                    </p>
                  </>
                )}
              </div>
              <div
                className={cn('hidden justify-end gap-2 pr-6 md:flex', {
                  'md:hidden': courses.length === visibleSlides
                })}
              >
                <ButtonBack
                  className={cn(BUTTON_STYLINGS, {
                    'cursor-default ': isFirstSlideVisible
                  })}
                  disabled={isFirstSlideVisible}
                >
                  <ChevronLeftIcon
                    className={cn('h-4 w-4 text-rb-black', {
                      'text-rb-gray-300': isFirstSlideVisible
                    })}
                    fill="currentColor"
                  />
                </ButtonBack>
                <ButtonNext
                  className={cn(BUTTON_STYLINGS, {
                    'cursor-default': isLastSlideVisible
                  })}
                  disabled={isLastSlideVisible}
                >
                  <ChevronRightIcon
                    className={cn('h-4 w-4 text-rb-black', {
                      'text-rb-gray-300': isLastSlideVisible
                    })}
                    fill="currentColor"
                  />
                </ButtonNext>
              </div>
            </div>

            <Slider
              style={{
                width: isMobileView ? `${totalSlidesWidth}px` : null
              }}
              classNameAnimation="transition-transform"
              trayProps={{
                draggable: true
              }}
            >
              {courses.map((course, i) => {
                return (
                  <Fragment key={i}>
                    {/* Hack - we render the below div with changing width to mimic behavior of homepage UiKit carousel: */}
                    {/* - sticking the last Slide to the right edge of the container when it becomes the active slide */}
                    {i === courses.length - visibleSlides && (
                      <div
                        className="h-[1px] transition-width"
                        style={{
                          width:
                            isMobileView && currentSlide >= i + 1
                              ? `${emptyElementWidth}px`
                              : 0
                        }}
                      />
                    )}

                    <Slide
                      index={i}
                      key={i}
                      innerClassName="flex h-full"
                      style={
                        isMobileView
                          ? { paddingRight: gapWidth, width: slideWidth }
                          : { paddingRight: gapWidth }
                      }
                    >
                      <CourseCard
                        pageLocation={pageLocation}
                        course={course}
                        hideBookmarkButton
                        showPremiumIconOverwrite={false}
                        styleVariant="live-featured"
                        courseSession={course.upcomingSessions?.[0]}
                      />
                    </Slide>
                  </Fragment>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    )
  }
)

Carousel.displayName = 'UpcomingLiveCoursesCarousel'

const CarouselWithStore = WithStore<CarouselProps, UpcomingLiveCoursesCarouselState>(
  Carousel,
  (state) => ({
    currentSlide: state.currentSlide,
    totalSlides: state.totalSlides,
    visibleSlides: state.visibleSlides
  })
)

const useCarouselSlidesCount = (params: { [key: string]: boolean }) => {
  const secondSlide = !params.isMobileView
  const thirdSlide = params.threeSlides

  return [thirdSlide, secondSlide, true].filter((slide) => slide).length
}

export default UpcomingLiveCoursesCarousel
