import { ChangeEvent, useReducer } from 'react'

import { ErrorMessage, Loading } from 'components'
import { ManageSeatSidePanel } from 'components/ManageSeatSidePanel'

import { TeamMembersPageSubscriptionSeatFragment, useTeamMembersPageQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import { useQueryParams } from 'hooks/useQueryParams'

import {
  trackCtaClicked,
  trackSidePanelDismissed,
  trackSidePanelDisplayed
} from 'utils/tracking/analytics'

import { ReactComponent as MagnifyingGlassIcon } from 'images/icon--magnifying-glass.svg'

import { SeatsList } from './SeatsList'

enum SeatManagementMode {
  ASSIGN_SEAT = 'assign_seat',
  UNASSIGN_SEAT = 'unassign_seat',
  COHORT_ACCESS = 'cohort_access'
}

type TeamMembersPageState = {
  searchTerm: string
  sidePanelIsOpen: boolean
  seatBeingManaged: TeamMembersPageSubscriptionSeatFragment | null
  recentlyUpdatedSeat: TeamMembersPageSubscriptionSeatFragment | null
  sidePanelMode: SeatManagementMode | null
  isCheckoutModalOpen: boolean
  isCohortPurchaseModalOpen: boolean
  cohortPassPurchasedCount: number | null
}

const initialState: TeamMembersPageState = {
  searchTerm: '',
  sidePanelIsOpen: false,
  seatBeingManaged: null,
  recentlyUpdatedSeat: null,
  sidePanelMode: null,
  isCheckoutModalOpen: false,
  isCohortPurchaseModalOpen: false,
  cohortPassPurchasedCount: null
}

type TeamMembersPageAction =
  | { type: 'CHANGE_SEARCH_INPUT'; payload: { searchTerm: string } }
  | {
      type: 'CLICK_REMOVE_SEAT_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_EDIT_COHORT_ACCESS_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_SAVE_COHORT_ACCESS_CTA_BUTTON'
      payload: { successfulMutation: boolean }
    }
  | {
      type: 'CLICK_ASSIGN_SEAT_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_UNASSIGN_SEAT_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_SIDE_PANEL_CLOSE_OR_CANCEL_CTA'
    }
  | {
      type: 'SIDE_PANEL_ACTION_SUCCESS'
    }

const reducer = (state: TeamMembersPageState, action: TeamMembersPageAction) => {
  switch (action.type) {
    case 'CHANGE_SEARCH_INPUT': {
      return { ...state, searchTerm: action.payload.searchTerm }
    }
    case 'CLICK_SIDE_PANEL_CLOSE_OR_CANCEL_CTA': {
      trackSidePanelDismissed({
        panel_group: 'manage_seats',
        panel_name: state.sidePanelMode! // if the panel opened then the mode should be  already be set
      })

      return {
        ...state,
        sidePanelIsOpen: false,
        seatBeingManaged: null,
        sidePanelMode: null
      }
    }
    case 'SIDE_PANEL_ACTION_SUCCESS': {
      return {
        ...state,
        sidePanelIsOpen: false,
        seatBeingManaged: null,
        sidePanelMode: null,
        recentlyUpdatedSeat: state.seatBeingManaged
      }
    }
    case 'CLICK_EDIT_COHORT_ACCESS_ACTION_CTA': {
      const sidePanelMode = SeatManagementMode.COHORT_ACCESS

      trackCtaClicked({
        cta_type: 'menu_select',
        cta_location: 'manage_seats_page',
        text: 'edit cohort access'
      })

      trackSidePanelDisplayed({
        panel_group: 'manage_seats',
        panel_name: sidePanelMode
      })

      return {
        ...state,
        sidePanelIsOpen: true,
        seatBeingManaged: action.payload.seatBeingManaged,
        sidePanelMode
      }
    }
    case 'CLICK_SAVE_COHORT_ACCESS_CTA_BUTTON': {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'manage_seat_side_panel',
        text: 'save (edit cohort access)'
      })

      return {
        ...state,
        sidePanelMode: SeatManagementMode.COHORT_ACCESS
      }
    }
    case 'CLICK_ASSIGN_SEAT_ACTION_CTA': {
      const sidePanelMode = SeatManagementMode.ASSIGN_SEAT

      trackCtaClicked({
        cta_type: 'menu_select',
        cta_location: 'manage_seats_page',
        text: 'invite member'
      })

      trackSidePanelDisplayed({
        panel_group: 'manage_seats',
        panel_name: sidePanelMode
      })

      return {
        ...state,
        sidePanelIsOpen: true,
        seatBeingManaged: action.payload.seatBeingManaged,
        sidePanelMode
      }
    }
    case 'CLICK_UNASSIGN_SEAT_ACTION_CTA': {
      const sidePanelMode = SeatManagementMode.UNASSIGN_SEAT

      trackCtaClicked({
        cta_type: 'menu_select',
        cta_location: 'manage_seats_page',
        text: 'remove member'
      })

      trackSidePanelDisplayed({
        panel_group: 'manage_seats',
        panel_name: sidePanelMode
      })
      return {
        ...state,
        sidePanelIsOpen: true,
        seatBeingManaged: action.payload.seatBeingManaged,
        sidePanelMode
      }
    }
    default: {
      console.error('Unhandled action type', action, state)
      return state
    }
  }
}

interface SearchBarProps {
  searchTerm: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const SearchBar = ({ searchTerm, onChange }: SearchBarProps) => {
  return (
    <div
      className="flex max-w-[364px] items-center justify-between rounded py-3 px-4 border border-gray-200 self-stretch"
      role="search"
    >
      <input
        data-test="seats-list-search-input"
        type="search"
        name="seats-list-search-input"
        className="focus:outline-none flex grow shrink-0 basis-0"
        placeholder="Search team members"
        aria-label="Search team members"
        value={searchTerm}
        onChange={onChange}
      />
      <MagnifyingGlassIcon className="h-4 w-4" />
    </div>
  )
}

const seatsFilter = (
  searchTerm: string,
  seats: TeamMembersPageSubscriptionSeatFragment[]
) => {
  return seats.filter((seat) => {
    if (!seat.subscriptionMember) return false

    const {
      user: { email, workEmail, fullName }
    } = seat.subscriptionMember

    return (
      fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      workEmail?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })
}

export interface TeamMembersPageProps {}
export const BuyerPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { seatBeingManaged, sidePanelMode, sidePanelIsOpen } = state
  const { queryParams } = useQueryParams()
  const hasSelectTeamGoals = queryParams.get('step') === 'select-team-for-goals'

  const { id: currentUserId } = useAssertCurrentUser()
  const { data, loading, error } = useTeamMembersPageQuery({
    variables: { id: currentUserId }
  })

  const activeSubscription = data?.user?.subscriptions?.active

  // NOTE: This may need to be debounced if we see performance issues
  const onSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'CHANGE_SEARCH_INPUT', payload: { searchTerm: value } })
  }

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  if (!activeSubscription) {
    return <ErrorMessage error="This user does not have an active subscription" />
  }

  const seats = seatsWithCurrentUserFirst({
    seats: activeSubscription.seats,
    currentUserId
  })

  return (
    <div className="flex flex-col pb-12">
      <div className="max-w-6xl">
        {hasSelectTeamGoals && (
          <div className="flex flex-col items-left bg-white p-8 pl-0 rounded-lg">
            <div className="text-[28px] font-sans font-medium mb-2">
              Who do you want to set a goal for?
            </div>
            <div className="font-normal font-sans  text-rb-gray-300 mb-4">
              Select people or groups to assign learning. Next, you’ll add your goal
            </div>
          </div>
        )}
        <div className="flex flex-wrap">
          <div className="float-left md:w-1/2">
            <SearchBar searchTerm={state.searchTerm} onChange={onSearchInputChange} />
          </div>
        </div>
        <div aria-atomic="true" aria-live="polite" aria-relevant="all">
          <SeatsList
            seats={state.searchTerm ? seatsFilter(state.searchTerm, seats) : seats}
            recentlyUpdatedSeat={state.recentlyUpdatedSeat}
            subscriptionHasDomainName={!!activeSubscription.domainName}
            isProvisionedByScim={activeSubscription.isProvisionedByScim}
            onAssignCohortPassAction={(
              seatBeingManaged: TeamMembersPageSubscriptionSeatFragment
            ) =>
              dispatch({
                type: 'CLICK_EDIT_COHORT_ACCESS_ACTION_CTA',
                payload: { seatBeingManaged }
              })
            }
            onSeatAssignAction={(
              seatBeingManaged: TeamMembersPageSubscriptionSeatFragment
            ) =>
              dispatch({
                type: seatBeingManaged.subscriptionMember
                  ? 'CLICK_UNASSIGN_SEAT_ACTION_CTA'
                  : 'CLICK_ASSIGN_SEAT_ACTION_CTA',
                payload: { seatBeingManaged }
              })
            }
          />
        </div>
        {sidePanelIsOpen && sidePanelMode && seatBeingManaged && (
          <ManageSeatSidePanel
            currentUserId={currentUserId}
            seats={[seatBeingManaged]}
            subscription={activeSubscription}
            subscriptionHasDomainName={!!activeSubscription.domainName}
            mode={sidePanelMode}
            onActionSuccess={() => dispatch({ type: 'SIDE_PANEL_ACTION_SUCCESS' })}
            onCancelOrCloseCtaClick={() =>
              dispatch({ type: 'CLICK_SIDE_PANEL_CLOSE_OR_CANCEL_CTA' })
            }
            dispatch={dispatch}
          />
        )}
      </div>
    </div>
  )
}

// helpers
const seatsWithCurrentUserFirst = ({
  seats,
  currentUserId
}: {
  seats: TeamMembersPageSubscriptionSeatFragment[]
  currentUserId: string
}) => {
  const currentUserSeat = seats.find(
    (s) => currentUserId === s.subscriptionMember?.user.id
  )

  const seatsWithoutCurrentUserSeat = currentUserSeat
    ? seats.filter((s) => s.subscriptionMember?.user.id !== currentUserId)
    : seats

  return currentUserSeat ? [currentUserSeat, ...seatsWithoutCurrentUserSeat] : seats
}
