import React, { useRef, useState } from 'react'

import { EMAIL_REGEX } from 'constants/regexes'

import { EmailChip } from './components/EmailChip'

export interface EmailChipsInputProps {
  onEmailsChange: (emails: string[]) => void
  maxEmails?: number
  maxEmailsError?: string
  zeroEmailsError?: string
  turnOffEmailCountValidation?: boolean
}
const EmailChipsInput = ({
  onEmailsChange,
  maxEmails,
  maxEmailsError,
  zeroEmailsError,
  turnOffEmailCountValidation
}: EmailChipsInputProps) => {
  const [emails, setEmails] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [invalidEmails, setInvalidEmails] = useState<string[]>([])
  const inputRef = useRef<HTMLInputElement>(null)

  const handleAddEmail = (email: string) => {
    if (email && !emails.includes(email)) {
      setEmails([...emails, email])
      onEmailsChange([...emails, email])
    }
  }

  const handleRemoveEmail = (email: string) => {
    const filteredEmails = emails.filter((e) => e !== email)
    setEmails(filteredEmails)
    onEmailsChange(filteredEmails)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault()
      if (EMAIL_REGEX.test(inputValue.trim())) {
        handleAddEmail(inputValue.trim())
        setInputValue('')
      }
    } else if (event.key === 'Backspace' && inputValue === '' && emails.length > 0) {
      setInputValue(emails[emails.length - 1])
      setEmails(emails.slice(0, -1))
    }
  }

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    setInvalidEmails([])

    const clipboardData = event.clipboardData.getData('Text')
    const cleanClipboardData = clipboardData.trim().replace(/,+$/, '') // remove surrounding whitespace and trailing commas
    const emailsToAdd = cleanClipboardData.split(/[\s,]+/).map((email) => email.trim())
    const validEmails = emailsToAdd
      .filter((email) => EMAIL_REGEX.test(email))
      .filter((email) => !emails.includes(email))
    const invalidEmails = emailsToAdd.filter((email) => !EMAIL_REGEX.test(email))

    setEmails((prevEmails) => [...prevEmails, ...validEmails])
    setInvalidEmails(invalidEmails)
    setInputValue('')
    onEmailsChange([...emails, ...validEmails])
  }

  const isMaxEmailsReached = maxEmails !== undefined && emails.length > maxEmails
  const isMaxEmailsZero = () => {
    if (maxEmails === undefined) return false
    return maxEmails === 0
  }

  return (
    <>
      {invalidEmails.length > 0 && (
        <div className="text-red-500 text-sm mt-2">
          There were invalid emails in your pasted list: {invalidEmails.join(', ')}
        </div>
      )}
      <div
        className="flex flex-wrap content-start justify-start items-start p-2 border border-gray-600 rounded cursor-text h-40 max-h-40 overflow-y-auto"
        onClick={handleInputClick}
        onKeyDown={handleInputKeyDown}
        tabIndex={0}
        role="button"
      >
        {emails.map((email) => (
          <EmailChip key={email} email={email} onRemove={handleRemoveEmail} />
        ))}
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onPaste={handlePaste}
          placeholder="Enter email and press Enter or Paste comma separated emails"
          className="flex-1 min-w-[150px] border-none outline-none text-sm"
          disabled={isMaxEmailsReached}
        />
      </div>
      {isMaxEmailsReached && !turnOffEmailCountValidation && (
        <div className="text-red-500 text-sm mt-2">
          {maxEmailsError ||
            `You have exceeded your seat limit.  Remove ${emails.length - maxEmails} seats.`}
        </div>
      )}
      {isMaxEmailsZero() && (
        <div className="text-red-500 text-sm mt-2">
          {zeroEmailsError || 'You have no remaining seats.  Remove seats or upgrade.'}
        </div>
      )}
    </>
  )
}

export default EmailChipsInput
