import { useEffect, useReducer } from 'react'

import AccountLayout from 'domains/Account/AccountLayout'
import { SeatsList } from 'domains/Subscription/SeatsList'

import { ErrorMessage, Loading } from 'components'
import { ManageSeatSidePanel, SeatManagementMode } from 'components/ManageSeatSidePanel'
import { useMultiInviteModal } from 'components/MultiInviteModal/useMultiInviteModal'

import {
  TeamMembersPageSubscriptionSeatFragment,
  useTeamMembersPageQuery,
  useToggleManualAssignSubscriptionMutation
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import {
  trackCtaClicked,
  trackSidePanelDismissed,
  trackSidePanelDisplayed
} from 'utils/tracking/analytics'

import { JoinTeamRequests } from './JoinTeamRequests/JoinTeamRequests'
import { SeatSummary } from './SeatSummary/SeatSummary'

type TeamMembersPageState = {
  searchTerm: string
  sidePanelIsOpen: boolean
  seatBeingManaged: TeamMembersPageSubscriptionSeatFragment | null
  bulkSeatsBeingManaged: TeamMembersPageSubscriptionSeatFragment[] | null
  recentlyUpdatedSeat: TeamMembersPageSubscriptionSeatFragment | null
  sidePanelMode: SeatManagementMode | null
  isCheckoutModalOpen: boolean
  isCohortPurchaseModalOpen: boolean
  cohortPassPurchasedCount: number | null
}

const initialState: TeamMembersPageState = {
  searchTerm: '',
  sidePanelIsOpen: false,
  seatBeingManaged: null,
  bulkSeatsBeingManaged: [],
  recentlyUpdatedSeat: null,
  sidePanelMode: null,
  isCheckoutModalOpen: false,
  isCohortPurchaseModalOpen: false,
  cohortPassPurchasedCount: null
}

export type TeamMembersPageAction =
  | { type: 'CHANGE_SEARCH_INPUT'; payload: { searchTerm: string } }
  | {
      type: 'CLICK_REMOVE_SEAT_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_EDIT_COHORT_ACCESS_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_SAVE_COHORT_ACCESS_CTA_BUTTON'
      payload: { successfulMutation: boolean }
    }
  | {
      type: 'CLICK_ASSIGN_SEAT_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_UNASSIGN_SEAT_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_SIDE_PANEL_CLOSE_OR_CANCEL_CTA'
    }
  | {
      type: 'SIDE_PANEL_ACTION_SUCCESS'
    }
  | {
      type: 'SIDE_PANEL_ACTION_ERROR'
    }
  | {
      type: 'BULK_UNASSIGN_SEATS'
      payload: { bulkSeatsBeingManaged: TeamMembersPageSubscriptionSeatFragment[] }
    }
  | {
      type: 'BULK_RESEND_INVITATIONS'
      payload: { bulkSeatsBeingManaged: TeamMembersPageSubscriptionSeatFragment[] }
    }

const reducer = (state: TeamMembersPageState, action: TeamMembersPageAction) => {
  switch (action.type) {
    case 'CHANGE_SEARCH_INPUT': {
      return { ...state, searchTerm: action.payload.searchTerm }
    }
    case 'CLICK_SIDE_PANEL_CLOSE_OR_CANCEL_CTA': {
      if (
        state.sidePanelMode &&
        state.sidePanelMode !== SeatManagementMode.BULK_UNASSIGN_SEATS &&
        state.sidePanelMode !== SeatManagementMode.ACTION_FINISHED &&
        state.sidePanelMode !== SeatManagementMode.BULK_RESEND_INVITATIONS
      ) {
        trackSidePanelDismissed({
          panel_group: 'manage_seats',
          panel_name: state.sidePanelMode
        })
      }

      return {
        ...state,
        sidePanelIsOpen: false,
        seatBeingManaged: null,
        sidePanelMode: null
      }
    }
    case 'SIDE_PANEL_ACTION_SUCCESS': {
      return {
        ...state,
        sidePanelIsOpen: false,
        seatBeingManaged: null,
        bulkSeatsBeingManaged: [],
        sidePanelMode: SeatManagementMode.ACTION_FINISHED,
        recentlyUpdatedSeat: state.seatBeingManaged
      }
    }
    case 'SIDE_PANEL_ACTION_ERROR': {
      return {
        ...state,
        sidePanelIsOpen: false,
        seatBeingManaged: null,
        bulkSeatsBeingManaged: [],
        sidePanelMode: SeatManagementMode.ACTION_FINISHED,
        recentlyUpdatedSeat: state.seatBeingManaged
      }
    }
    case 'CLICK_EDIT_COHORT_ACCESS_ACTION_CTA': {
      const sidePanelMode = SeatManagementMode.COHORT_ACCESS

      trackCtaClicked({
        cta_type: 'menu_select',
        cta_location: 'manage_seats_page',
        text: 'edit cohort access'
      })

      trackSidePanelDisplayed({
        panel_group: 'manage_seats',
        panel_name: sidePanelMode
      })

      return {
        ...state,
        sidePanelIsOpen: true,
        seatBeingManaged: action.payload.seatBeingManaged,
        sidePanelMode
      }
    }
    case 'CLICK_SAVE_COHORT_ACCESS_CTA_BUTTON': {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'manage_seat_side_panel',
        text: 'save (edit cohort access)'
      })

      return {
        ...state,
        sidePanelMode: SeatManagementMode.COHORT_ACCESS
      }
    }
    case 'CLICK_ASSIGN_SEAT_ACTION_CTA': {
      const sidePanelMode = SeatManagementMode.ASSIGN_SEAT

      trackCtaClicked({
        cta_type: 'menu_select',
        cta_location: 'manage_seats_page',
        text: 'invite member'
      })

      trackSidePanelDisplayed({
        panel_group: 'manage_seats',
        panel_name: sidePanelMode
      })

      return {
        ...state,
        sidePanelIsOpen: true,
        seatBeingManaged: action.payload.seatBeingManaged,
        sidePanelMode
      }
    }
    case 'CLICK_UNASSIGN_SEAT_ACTION_CTA': {
      const sidePanelMode = SeatManagementMode.UNASSIGN_SEAT

      trackCtaClicked({
        cta_type: 'menu_select',
        cta_location: 'manage_seats_page',
        text: 'remove member'
      })

      trackSidePanelDisplayed({
        panel_group: 'manage_seats',
        panel_name: sidePanelMode
      })
      return {
        ...state,
        sidePanelIsOpen: true,
        seatBeingManaged: action.payload.seatBeingManaged,
        sidePanelMode
      }
    }
    case 'BULK_UNASSIGN_SEATS': {
      console.log('seats being managed', action.payload.bulkSeatsBeingManaged)
      console.log('inside bulk unassign seats')
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'manage_seats_page',
        text: 'bulk remove members'
      })

      return {
        ...state,
        sidePanelIsOpen: true,
        bulkSeatsBeingManaged: action.payload.bulkSeatsBeingManaged,
        sidePanelMode: SeatManagementMode.BULK_UNASSIGN_SEATS
      }
    }
    case 'BULK_RESEND_INVITATIONS': {
      return {
        ...state,
        sidePanelIsOpen: true,
        sidePanelMode: SeatManagementMode.BULK_RESEND_INVITATIONS,
        bulkSeatsBeingManaged: action.payload.bulkSeatsBeingManaged
      }
    }

    default: {
      console.error('Unhandled action type', action, state)
      return state
    }
  }
}

export interface TeamMembersPageProps {}
export const TeamMembersPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { seatBeingManaged, sidePanelMode, sidePanelIsOpen, bulkSeatsBeingManaged } =
    state

  const { id: currentUserId, is } = useAssertCurrentUser()
  const { data, loading, error, refetch } = useTeamMembersPageQuery({
    variables: { id: currentUserId }
  })

  /* on mobile, the scrolling for the members table is weird on
  iPhones unless we have the hackish thing below
   */
  useEffect(() => {
    const pageElement = document.getElementById('page')
    if (pageElement) {
      pageElement.classList.remove('overflow-y-auto')
    }

    return () => {
      if (pageElement) {
        pageElement.classList.add('overflow-y-auto')
      }
    }
  }, [])

  const activeSubscription = data?.user?.subscriptions?.active

  const { autoAssignCoursePass } = useFeatureFlags()
  const [setManualAssign] = useToggleManualAssignSubscriptionMutation()

  const { openMultiInviteModal } = useMultiInviteModal(activeSubscription!)

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  if (!activeSubscription) {
    return <ErrorMessage error="This user does not have an active subscription" />
  }

  const assignedSeatcount =
    activeSubscription.seats.length -
    activeSubscription.seats.filter((s) => !s.subscriptionMember).length

  const assignedCohortCreditCount =
    activeSubscription.cohortCredits.unexpired.length -
    activeSubscription.cohortCredits.assignable.length

  const seats = seatsWithCurrentUserFirst({
    seats: activeSubscription.seats,
    currentUserId
  })

  const toggleManualAssign = async () => {
    setManualAssign({
      variables: {
        input: {
          id: activeSubscription.id,
          manualAssign: !activeSubscription.manualAssign
        }
      }
    }).then(() => {
      // Update the subscription by refreshing the page query
      refetch()
    })
  }

  return (
    <div className="flex flex-col pb-12">
      <AccountLayout>
        <div className="mb-5 md:mb-10 flex flex-col justify-between md:flex-row">
          <SeatSummary
            subscription={activeSubscription}
            totalSeatCount={activeSubscription.seats.length}
            assignedSeatcount={assignedSeatcount}
            totalCohortCreditCount={activeSubscription.cohortCredits.unexpired.length}
            assignedCohortCreditCount={assignedCohortCreditCount}
            isAssistantAdmin={is.assistantAdmin || false}
            manualAssign={activeSubscription.manualAssign}
            onToggleManualAssign={toggleManualAssign}
            autoAssignCoursePass={autoAssignCoursePass}
          />
        </div>
        <JoinTeamRequests
          subscriptionId={activeSubscription.id}
          subscriptionHasDomainName={!!activeSubscription.domainName}
          hasSeatsAvailable={assignedSeatcount < activeSubscription.seats.length}
        />
        <div aria-atomic="true" aria-live="polite" aria-relevant="all">
          <SeatsList
            sidePanelIsOpen={sidePanelIsOpen}
            seats={seats}
            recentlyUpdatedSeat={state.recentlyUpdatedSeat}
            subscriptionHasDomainName={!!activeSubscription.domainName}
            isProvisionedByScim={activeSubscription.isProvisionedByScim}
            onAssignCohortPassAction={(
              seatBeingManaged: TeamMembersPageSubscriptionSeatFragment
            ) =>
              dispatch({
                type: 'CLICK_EDIT_COHORT_ACCESS_ACTION_CTA',
                payload: { seatBeingManaged }
              })
            }
            onSeatAssignAction={(
              seatBeingManaged: TeamMembersPageSubscriptionSeatFragment
            ) =>
              dispatch({
                type: seatBeingManaged.subscriptionMember
                  ? 'CLICK_UNASSIGN_SEAT_ACTION_CTA'
                  : 'CLICK_ASSIGN_SEAT_ACTION_CTA',
                payload: { seatBeingManaged }
              })
            }
            dispatch={dispatch}
            sidePanelMode={state.sidePanelMode}
            handleOpenMultiInviteModal={() => {
              openMultiInviteModal(
                // most of these are pre-seeded so that we don't have to wait for the information to load in the modal
                currentUserId,
                activeSubscription.seats.length,
                assignedSeatcount,
                activeSubscription,
                activeSubscription.teamInviteToken ?? '',
                refetch
              )
            }}
          />
        </div>
        {sidePanelIsOpen &&
          sidePanelMode &&
          (seatBeingManaged || bulkSeatsBeingManaged?.length) && (
            <ManageSeatSidePanel
              subscriptionHasDomainName={!!activeSubscription.domainName}
              currentUserId={currentUserId}
              seats={seatBeingManaged ? [seatBeingManaged] : bulkSeatsBeingManaged ?? []}
              subscription={activeSubscription}
              mode={sidePanelMode}
              onActionSuccess={() => dispatch({ type: 'SIDE_PANEL_ACTION_SUCCESS' })}
              onActionError={() => dispatch({ type: 'SIDE_PANEL_ACTION_ERROR' })}
              onCancelOrCloseCtaClick={() =>
                dispatch({ type: 'CLICK_SIDE_PANEL_CLOSE_OR_CANCEL_CTA' })
              }
              dispatch={dispatch}
            />
          )}
      </AccountLayout>
    </div>
  )
}

// helpers
const seatsWithCurrentUserFirst = ({
  seats,
  currentUserId
}: {
  seats: TeamMembersPageSubscriptionSeatFragment[]
  currentUserId: string
}) => {
  const currentUserSeat = seats.find(
    (s) => currentUserId === s.subscriptionMember?.user.id
  )

  const seatsWithoutCurrentUserSeat = currentUserSeat
    ? seats.filter((s) => s.subscriptionMember?.user.id !== currentUserId)
    : seats

  return currentUserSeat ? [currentUserSeat, ...seatsWithoutCurrentUserSeat] : seats
}
