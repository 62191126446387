import { CloseIcon } from 'components/icons'

export interface EmailChipProps {
  email: string
  onRemove: (email: string) => void
}

export const EmailChip = ({ email, onRemove }: EmailChipProps) => {
  return (
    <div className="inline-block px-2 py-1 m-0.5 ml-0 mr-1 bg-[#F6F6F5] rounded-md border-2 border-[#BBBDB9] text-sm">
      {email}
      <button className="ml-2 text-gray-600 align-middle" onClick={() => onRemove(email)}>
        <CloseIcon className="h-4 w-4 stroke-2" />
      </button>
    </div>
  )
}
