import { isEqual, sortBy, uniqBy } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD } from 'pages/CoursesPage/CourseCard'
import {
  COURSE_FILTER_LIVE,
  COURSE_FILTER_ON_DEMAND
} from 'pages/CoursesPage/CoursesFilters'
import CustomPageTitle from 'pages/CustomPageTitle'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { ErrorMessage } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'
import Button from 'components/Button'
import Loading from 'components/Loading'
import { usePage } from 'components/PageHeader/usePage'
import PremiumBadge from 'components/PremiumBadge'
import Tabs from 'components/Tabs'
import { CardVariants } from 'components/cards/Content/BaseCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS
} from 'components/cards/Content/CourseCard'
import DropdownMultiSelect from 'components/dropdowns/DropdownMultiSelect/DropdownMultiSelect'
import DropdownSelect from 'components/dropdowns/DropdownSelect/DropdownSelect'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'

import {
  COURSES_LIVE_PATH,
  COURSES_MY_COURSES_PATH,
  COURSES_ON_DEMAND_PATH,
  COURSES_PATH
} from 'constants/courses'

import {
  useCoursesListFiltersQuery,
  useCoursesListQuery,
  useCoursesListUserQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import {
  trackFilterApplied,
  trackNavigationClicked,
  trackSortSelected
} from 'utils/tracking/analytics'

import {
  COURSE_SORT_TYPE_ALPHA,
  COURSE_SORT_TYPE_DURATION,
  COURSE_SORT_TYPE_POPULARITY,
  COURSE_SORT_TYPE_START_DATE,
  SORT_TYPE_LABEL_MAP
} from './CoursesListSort'
import MyCoursesTab from './MyCoursesTab'

const INITIAL_DISPLAYED_COURSES = 5
const COURSES_TAB_MY_COURSES = 'my-courses'

type CombinedFiltersType = {
  availability: string[]
  topics: string[]
}

type FilterType = 'topics' | 'availability'

const combinedFilterCount = (combinedFilters: CombinedFiltersType) =>
  Object.keys(combinedFilters).reduce(
    (count, filterType: FilterType) => count + combinedFilters[filterType].length,
    0
  )

interface CoursesProps {
  activeTab: string
  filters: CombinedFiltersType
  sort: string
  pageLocation: string
  filterTypeJustApplied?: FilterType | null
  setFilterTypeJustApplied: Dispatch<SetStateAction<null | FilterType>>
  clearFiltersForType: (filterType: FilterType) => void
}

const Courses = ({
  activeTab,
  filters,
  pageLocation,
  sort,
  filterTypeJustApplied,
  setFilterTypeJustApplied,
  clearFiltersForType
}: CoursesProps) => {
  const { isLoggedIn, currentUser } = useCurrentUser()
  const history = useHistory()

  const [displayedCourses, setDisplayedCourses] = useState(INITIAL_DISPLAYED_COURSES)

  const [currentTopics, setCurrentTopics] = useState(filters.topics)
  const [currentTab, setCurrentTab] = useState(activeTab)

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const { data, loading, error } = useCoursesListQuery({
    variables: {
      topicSlugs: filters.topics,
      availability: filters.availability,
      sortOrder: sort
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted(data) {
      if (isEqual(filters.topics, currentTopics)) {
        return
      }
      if (currentTab !== activeTab) {
        // If the current tab is not the active tab, the filters will get reset (no-op)
        setCurrentTab(activeTab)
        return
      }
      setCurrentTopics(filters.topics)
      // tracking filterApplied here so can track the fetched result counts with this track
      if (filterTypeJustApplied && data) {
        trackFilterApplied({
          filter_location: pageLocation,
          filter_changed: filterTypeJustApplied,
          filters: filters,
          active_filter_count: combinedFilterCount(filters),
          results_count: data?.cclCourses?.length || 0
        })

        setFilterTypeJustApplied(null)
      }
    }
  })

  const {
    data: userData,
    loading: bookmarksLoading,
    error: bookmarksError
  } = useCoursesListUserQuery({ skip: !isLoggedIn })

  if (bookmarksError || error) {
    return <ErrorMessage error={error} />
  }

  if (bookmarksLoading || loading || !data?.cclCourses) {
    return <Loading className="py-32" />
  }

  const cclCourses = data.cclCourses

  const courseBookmarks = userData?.currentUser?.courseBookmarks || []

  const additionalRelatedIdentifiers = {
    is_filtered_reference: combinedFilterCount(filters) > 0,
    is_empty_index_results: false
  }

  const clearFilters = () => {
    clearFiltersForType('availability')
    clearFiltersForType('topics')
  }

  const handleExploreAllClick = () => {
    clearFilters()
    history.push(COURSES_ON_DEMAND_PATH)
  }

  if (cclCourses.length === 0 && filters.topics.length > 0) {
    return (
      <div className="flex flex-col w-full h-[400px] items-center justify-center bg-rb-gray-50 mt-10 rounded">
        <RfHeader2>We don&apos;t have that... yet</RfHeader2>
        <RfHeader3 className="text-rb-gray-300">
          But we are frequently adding new courses, so stay tuned
        </RfHeader3>
        <Button
          variant="fill"
          color="teal"
          size="large"
          onClick={handleExploreAllClick}
          data-canny-link
        >
          Explore all courses
        </Button>
      </div>
    )
  }

  if (cclCourses.length === 0 && activeTab === COURSE_FILTER_LIVE) {
    return (
      <div className="flex flex-col w-full h-[400px] items-center justify-center bg-rb-gray-50 mt-10 rounded">
        <RfHeader2>There aren’t any live courses scheduled right now</RfHeader2>
        <RfHeader3 className="text-rb-gray-300">
          Keep checking back or explore on demand courses{' '}
        </RfHeader3>
        <Button
          variant="fill"
          color="teal"
          size="large"
          onClick={handleExploreAllClick}
          data-canny-link
        >
          View on demand courses
        </Button>
      </div>
    )
  }

  const onShowMoreClick = () => {
    setDisplayedCourses(cclCourses.length)
  }

  const sliceLength = isLoggedIn ? cclCourses.length : displayedCourses

  const destinationType =
    activeTab === COURSE_FILTER_ON_DEMAND && currentUser?.is.paidMember
      ? COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
      : COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS

  return (
    <>
      <div className="mt-6 flex flex-col space-y-4">
        {cclCourses.slice(0, sliceLength).map((course) => {
          const bookmark = courseBookmarks.find(
            (courseBookmark) => courseBookmark.cclCourse?.id === course.id
          )

          return (
            <CourseCard
              destinationType={destinationType}
              pageLocation={pageLocation}
              locationType="index"
              activeTab={activeTab}
              additionalRelatedIdentifiers={additionalRelatedIdentifiers}
              variant={CardVariants.Horizontal}
              courseSession={course.upcomingSessions?.[0]}
              course={course}
              key={course.id}
              hideBookmarkButton={!isLoggedIn}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              bookmark={bookmark}
              showPremiumIconOverwrite={!isLoggedIn ? false : undefined}
              styleVariant={!isLoggedIn ? 'logged-out-courses-index' : undefined}
            />
          )
        })}

        {!isLoggedIn && displayedCourses < cclCourses.length && (
          <Button
            variant="outline"
            onClick={onShowMoreClick}
            className="self-center"
            size="small"
          >
            Show all courses
          </Button>
        )}
      </div>
      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={userData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={!userData?.currentUser?.hasSeenCollectionsPrompt}
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction="bookmark"
      />
    </>
  )
}

function getValidSelectedTopics(
  allCourseTopics: Array<{ slug: string }>,
  selectedTopics: Array<string>
) {
  const validFilterSlugs = allCourseTopics.map((topic) => topic.slug)
  return selectedTopics.filter((slug) => validFilterSlugs.includes(slug))
}

function getAllTopicsFromCoursesListFiltersQueryResult(
  data: ReturnType<typeof useCoursesListFiltersQuery>['data']
) {
  const cclCourseCclTopics = data?.cclCourseCclTopics || []

  const allCourseTopics = sortBy(uniqBy([...cclCourseCclTopics], 'id'), 'title')

  return allCourseTopics
}

export const CoursesList = () => {
  const history = useHistory()
  const { isLoggedIn, currentUser } = useCurrentUser()
  const { setPageTitle, setPageAboveHeader, setPageSubtitle } = usePage()
  const location = useLocation()

  const { ref16263TopicBasedBrowsing, showNewCourseGating } = useFeatureFlags()

  const locationSearchString = location.search
  const searchParams = useMemo(
    () => new URLSearchParams(locationSearchString),
    [locationSearchString]
  )
  const [tabSearchParam, setTabSearchParam] = useState(searchParams.get('tab'))

  const activeTab = tabSearchParam || COURSE_FILTER_ON_DEMAND

  const selectedFilters = useMemo(() => {
    return {
      availability:
        searchParams.get('tab') === 'my-courses' ? null : searchParams.get('tab'),
      topics: searchParams.get('topics')?.split(',').filter(Boolean) || []
    }
  }, [searchParams])

  const initialSort =
    selectedFilters.availability === COURSE_FILTER_ON_DEMAND
      ? showNewCourseGating
        ? COURSE_SORT_TYPE_POPULARITY
        : COURSE_SORT_TYPE_ALPHA
      : COURSE_SORT_TYPE_START_DATE

  const [selectedSortType, setSelectedSortType] = useState(initialSort)

  const [filterTypeJustApplied, setFilterTypeJustApplied] = useState<null | FilterType>(
    null
  )

  useEffect(() => {
    if (
      activeTab === COURSE_FILTER_LIVE &&
      selectedSortType === COURSE_SORT_TYPE_POPULARITY
    ) {
      setSelectedSortType(COURSE_SORT_TYPE_START_DATE)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  useEffect(() => {
    if (!isLoggedIn || currentUser?.is.freeUser) {
      setPageTitle(
        <CustomPageTitle title="Courses">
          <PremiumBadge />
        </CustomPageTitle>
      )
    }

    if (isLoggedIn) {
      if (ref16263TopicBasedBrowsing) {
        setPageAboveHeader(
          <div className="mb-8">
            <BaseBreadcrumbs
              backIcon={
                <ChevronLeftIcon width={16} height={16} className="mr-4 fill-rb-black" />
              }
              oneLevel
              onClick={() => {
                trackNavigationClicked({
                  type: 'hyperlink text',
                  text: 'back to explore',
                  location: location.pathname,
                  destination: '/explore'
                })
              }}
              breadcrumbPages={[{ title: 'Explore', path: '/explore' }]}
            />
          </div>
        )
      }

      setPageSubtitle('Gain the expertise to unlock step-change career growth')
    }

    return () => {
      setPageTitle(null)
      setPageAboveHeader(null)
      setPageSubtitle(null)
    }
  }, [
    currentUser,
    setPageTitle,
    setPageAboveHeader,
    setPageSubtitle,
    isLoggedIn,
    location.pathname,
    ref16263TopicBasedBrowsing
  ])

  function setTopicsQueryParam(topics: string[]) {
    const topicSlugs = topics.join(',')
    if (topicSlugs.length === 0) {
      searchParams.delete('topics')
    } else {
      searchParams.set('topics', topicSlugs)
    }

    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString()
    })

    setFilterTypeJustApplied('topics')
  }

  const { data, loading, error } = useCoursesListFiltersQuery({
    onCompleted: (data) => {
      const allTopicsFilters = getAllTopicsFromCoursesListFiltersQueryResult(data)
      const validSelectedTopics = getValidSelectedTopics(
        allTopicsFilters,
        selectedFilters.topics
      )

      setTopicsQueryParam(validSelectedTopics)
    }
  })

  const allCourseTopics = getAllTopicsFromCoursesListFiltersQueryResult(data)

  useEffect(() => {
    const tabSearchParam = searchParams.get('tab')
    const availabilitySearchParam = searchParams.get('availability')

    if (availabilitySearchParam) {
      searchParams.delete('availability')
      searchParams.set('tab', availabilitySearchParam)

      history.replace({
        pathname: window.location.pathname,
        search: searchParams.toString()
      })
      return
    }

    if (
      !tabSearchParam ||
      (!isLoggedIn &&
        ![COURSE_FILTER_ON_DEMAND, COURSE_FILTER_LIVE].includes(tabSearchParam))
    ) {
      searchParams.set('tab', isLoggedIn ? COURSE_FILTER_ON_DEMAND : COURSE_FILTER_LIVE)
      history.replace({
        pathname: COURSES_PATH,
        search: searchParams.toString()
      })
      setTabSearchParam(isLoggedIn ? 'on-demand' : 'live')
    }

    // We only want this to run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (loading) {
    return <Loading />
  }

  const getPageLocationForTracking = () => {
    if (isLoggedIn) {
      if (currentUser?.is?.member) {
        return '/courses (paid)'
      }
      return '/courses (free)'
    }
    return '/courses (anon)'
  }

  const clearFiltersForType = (filterType: FilterType) => {
    searchParams.delete(filterType)

    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString()
    })
  }

  const pageLocation = getPageLocationForTracking()

  function handleTopicSelection(topics: string[]) {
    setTopicsQueryParam(topics)
  }

  const handleSelectSortType = (sortType: string) => {
    if (sortType === selectedSortType) return

    trackSortSelected({
      location: pageLocation,
      sort_by: sortType.toLowerCase()
    })
    setSelectedSortType(sortType)
  }

  const tabs = [
    {
      label: 'Live',
      onClick: () => {
        setTabSearchParam('live')
        history.push(COURSES_LIVE_PATH)

        trackNavigationClicked({
          text: 'live',
          destination: COURSES_LIVE_PATH,
          type: 'pill',
          location: '/courses'
        })
      },
      isActive: tabSearchParam === COURSE_FILTER_LIVE
    },
    {
      label: 'On demand',
      onClick: () => {
        setTabSearchParam('on-demand')
        history.push(COURSES_ON_DEMAND_PATH)
        trackNavigationClicked({
          text: 'on demand',
          destination: COURSES_ON_DEMAND_PATH,
          type: 'pill',
          location: '/courses'
        })
      },
      isActive: tabSearchParam === COURSE_FILTER_ON_DEMAND
    },
    ...(isLoggedIn
      ? [
          {
            label: 'My courses',
            onClick: () => {
              setTabSearchParam('my-courses')
              history.push(COURSES_MY_COURSES_PATH)
              trackNavigationClicked({
                text: 'my courses',
                destination: COURSES_MY_COURSES_PATH,
                type: 'pill',
                location: '/courses'
              })
            },
            isActive: tabSearchParam === COURSES_TAB_MY_COURSES
          }
        ]
      : [])
  ]

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center space-x-4">
        <Tabs tabs={tabs} />
      </div>
      <div className="flex justify-between mt-6">
        {allCourseTopics.length > 0 && tabSearchParam !== COURSES_TAB_MY_COURSES && (
          <DropdownMultiSelect
            displayText="Topics"
            data={allCourseTopics.map((topic) => ({
              label: topic.title,
              value: topic.slug
            }))}
            className="h-[40px] w-[110px] md:min-w-[220px]"
            dropdownClassName="min-w-[220px] w-full"
            containerClassName={'w-[110px] md:w-full'}
            selectedItems={selectedFilters.topics}
            onSelection={handleTopicSelection}
          />
        )}
        {selectedFilters.availability === COURSE_FILTER_LIVE && (
          <DropdownSelect
            data={[
              {
                value: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_START_DATE], // the value and label are backwards because the dropdown renders the value as the option text
                label: COURSE_SORT_TYPE_START_DATE
              },
              {
                value: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_DURATION],
                label: COURSE_SORT_TYPE_DURATION
              },
              {
                value: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_ALPHA],
                label: COURSE_SORT_TYPE_ALPHA
              }
            ]}
            value={selectedSortType}
            onChange={handleSelectSortType}
            label={'Sort By'}
            className={
              'hidden sm:flex w-[350px] shrink-0 md:w-[300px] flex-row items-center justify-end gap-2'
            }
            labelClassName="text-rb-gray-300"
            dropdownClassName="-right-3"
            noBorder
          />
        )}
      </div>
      {tabSearchParam === COURSES_TAB_MY_COURSES ? (
        <MyCoursesTab />
      ) : (
        <Courses
          activeTab={activeTab}
          filters={{
            availability: selectedFilters.availability
              ? [selectedFilters.availability]
              : [],
            topics: selectedFilters.topics
          }}
          sort={
            activeTab === COURSE_FILTER_ON_DEMAND && showNewCourseGating
              ? COURSE_SORT_TYPE_POPULARITY
              : selectedSortType
          }
          pageLocation={pageLocation}
          setFilterTypeJustApplied={setFilterTypeJustApplied}
          filterTypeJustApplied={filterTypeJustApplied}
          clearFiltersForType={clearFiltersForType}
        />
      )}
    </div>
  )
}

export default CoursesList
