import { LearningGoalSessionData } from 'pages/goals/type'

export const stepProgressPercentCalculator = (
  sessionData: LearningGoalSessionData | null | undefined
) => {
  let completedSteps = 0
  let totalSteps = 0
  const data = sessionData?.data

  if (data?.todo?.length && data?.completedSteps?.length) {
    totalSteps = data.todo.length + data.completedSteps.length
  }

  if (data?.completedSteps?.length) {
    completedSteps = data.completedSteps.length
  }

  if (sessionData?.status === 'completed') {
    return {
      completedSteps: 1,
      totalSteps: 1
    }
  }

  return {
    completedSteps,
    totalSteps
  }
}
