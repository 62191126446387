import { useRef, useState } from 'react'

import { OnboardingChecklistWrapper } from 'domains/Onboarding/Checklist/OnboardingChecklistWrapper'

import Button from 'components/Button'
import { ProgressRing } from 'components/ProgressRing/ProgressRing'
import { useRefetch } from 'components/RefetchProvider'

import { useChecklistTasksQuery } from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

export const OnboardingChecklistIndicator = () => {
  const indicatorRef = useRef<HTMLDivElement>(null)

  const [showChecklist, setShowChecklist] = useState(false)
  const checklistName = 'onboarding'
  const buttonText = 'Get Started'
  const { fetchChecklistTasks } = useRefetch()
  const { data, refetch } = useChecklistTasksQuery({
    variables: {
      checklistName
    }
  })

  if (fetchChecklistTasks) {
    refetch()
  }

  const handleClick = () => {
    trackCtaClicked({
      cta_location: 'header',
      cta_type: 'button',
      text: buttonText.toLowerCase()
    })
    setShowChecklist(!showChecklist)
  }

  if (!data || data.checklistTasks.length < 1) {
    return null
  }

  const percentCompleted = Math.round(
    (data.checklistTasks.filter((task) => task.completed).length /
      data.checklistTasks.length) *
      100
  )

  return (
    <div ref={indicatorRef} className="relative">
      <Button
        className="flex flex-row items-center group h-10 px-4 gap-2 text-sm"
        variant="text-only"
        onClick={handleClick}
      >
        <div className="inline-block">{buttonText}</div>
        <ProgressRing
          size={32}
          className="text-rb-gray-300"
          fontSize={10}
          trackWidth={3}
          progressPercent={percentCompleted}
          strokeClassName="stroke-rb-info"
          completedClassName="bg-rb-blue-100"
        />
      </Button>
      {showChecklist && (
        <OnboardingChecklistWrapper
          checklistTasks={data.checklistTasks}
          closeChecklist={() => setShowChecklist(!showChecklist)}
          openButtonRef={indicatorRef}
        />
      )}
    </div>
  )
}
