import React, { useRef, useState } from 'react'

interface CheckboxProps {
  checked: boolean
  onChange: () => void
  screenReaderLabel: string
  disabled?: boolean
  fillColor?: string
  children?: React.ReactNode
}

/* Checkbox.tsx and StyledCheckbox.tsx don't use an input form type, and Checkbox.tsx is not usable by keyboard, so we can deprecate those and use this accessible checkbox instead */

const AccessibleCheckbox = ({
  checked,
  onChange,
  screenReaderLabel,
  disabled = false,
  fillColor = '#1A6C69',
  children
}: CheckboxProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const [isKeyboardFocus, setIsKeyboardFocus] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = () => {
    onChange()
  }

  const handleFocus = () => {
    setIsFocused(true)
    setIsKeyboardFocus(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
    setIsKeyboardFocus(false)
  }

  const handleMouseDown = () => {
    setIsKeyboardFocus(false)
  }

  return (
    <label
      className={`flex cursor-pointer items-center ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      <input
        ref={inputRef}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleMouseDown}
        className="sr-only"
        aria-label={screenReaderLabel}
        aria-checked={checked}
        disabled={disabled}
      />
      <span
        data-testid="checkbox-outline"
        className={`mr-2 shrink-0 relative inline-block w-6 h-6 ${
          isFocused && isKeyboardFocus
            ? 'outline outline-2 outline-blue-500 outline-offset-2'
            : ''
        }`}
      >
        <svg
          className="w-6 h-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <rect
            data-testid="checkbox-rect"
            x="0.75"
            y="0.75"
            width="18.5"
            height="18.5"
            rx="1.25"
            fill={checked ? (disabled ? '#A0AEC0' : fillColor) : 'white'}
            stroke={checked ? (disabled ? '#A0AEC0' : fillColor) : '#d3d2d3'}
            strokeWidth="1.5"
          />
          {checked && (
            <path
              d="M5 10L8.5 13.5L15 7"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          )}
        </svg>
      </span>
      {children}
    </label>
  )
}

export default AccessibleCheckbox
