import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { useGlobalModal } from 'components/GlobalModal'

import { Seat, TeamMembersPageSubscriptionFragment } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { MultiInviteModalContent } from './MultiInviteModalContent'

export const useMultiInviteModal = (
  subscriptionIn: TeamMembersPageSubscriptionFragment
) => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const [emails, setEmails] = useState<string[]>([])
  const emailsRef = useRef<string[]>(emails)
  const navigate = useNavigate()

  const { teamInvitationInvites } = useFeatureFlags()

  const handleEmailsChange = (newEmails: string[]) => {
    setEmails(newEmails)
    emailsRef.current = newEmails
  }

  const getNonNullPendingSeats = (
    subscription: TeamMembersPageSubscriptionFragment
  ): Seat[] => {
    return subscription.seats
      .filter(
        (seat): seat is Seat =>
          seat.subscriptionMember !== null &&
          seat.subscriptionMember?.status === 'pending'
      )
      .sort((a, b) => {
        if (a.subscriptionMember?.email && b.subscriptionMember?.email) {
          return a.subscriptionMember.email.localeCompare(b.subscriptionMember.email)
        }
        return 0
      })
  }

  const openMultiInviteModal = (
    currentUserId: string,
    totalSeatCount: number,
    assignedSeatCount: number,
    subscription: TeamMembersPageSubscriptionFragment,
    subscriptionToken: string,
    refetchSubscription: () => void
  ) => {
    if (!teamInvitationInvites) {
      // this feature is not enabled by a flag
      return
    }
    openGlobalModal(
      // most of these are pre-seed so that we don't have to wait for the information to load in the modal
      <MultiInviteModalContent
        currentUserId={currentUserId}
        totalSeatCount={totalSeatCount}
        assignedSeatCount={assignedSeatCount}
        pendingSeats={getNonNullPendingSeats(subscription)}
        subscriptionToken={subscriptionToken}
        handleEmailsChange={handleEmailsChange}
        onUpgradePlanClick={() => {
          closeGlobalModal()
          navigate('/manage-plan')
        }}
        subscriptionIn={subscriptionIn}
        updateSubscription={refetchSubscription}
        refetchPendingSeats={getNonNullPendingSeats}
      />,
      {
        className: 'mx-2 max-w-[500px] rounded-lg'
      }
    )
  }

  return {
    openMultiInviteModal
  }
}
