import renderSortable from 'domains/Shared/RenderSortable'

import Button from 'components/Button'
import Loading from 'components/Loading'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'

import { COURSES_ON_DEMAND_PATH } from 'constants/courses'

import {
  MarketingCourseCourseCardPartsFragment,
  MyCourseCclCourseInProgressPartsFragment,
  MyCoursesCurrentUserPartsFragment,
  MyCoursesUserCohortsPartsFragment,
  MyCoursesUserCoursesPartsFragment,
  MyCoursesUserProgramPartsFragment,
  useMyCoursesQuery
} from 'gql'

import { processUserCoursesData } from 'utils/userCourseDataUtils'

export interface MyCoursesProps {
  loading?: boolean
  userCourses?: MyCoursesUserCoursesPartsFragment | null
  userCohorts?: MyCoursesUserCohortsPartsFragment | null
  userProgramsInProgress?: MyCoursesUserProgramPartsFragment[] | null
  inProgressCourses?: MyCourseCclCourseInProgressPartsFragment[] | null
  legacyMarketingCourses?: MarketingCourseCourseCardPartsFragment[] | null
  marketplaceMarketingCourses?: MarketingCourseCourseCardPartsFragment[] | null
  user?: MyCoursesCurrentUserPartsFragment | null
}

const MyCourses = ({
  loading,
  user,
  userCourses,
  userCohorts,
  userProgramsInProgress,
  legacyMarketingCourses,
  marketplaceMarketingCourses,
  inProgressCourses
}: MyCoursesProps) => {
  if (loading) {
    return <Loading />
  }

  const {
    legacyCoursesByProgramSlug,
    marketplaceCoursesByCourseSanityId,
    sortedCompletedSortables,
    sortedInProgressSortables
  } = processUserCoursesData(
    userCourses,
    userCohorts,
    userProgramsInProgress,
    inProgressCourses,
    legacyMarketingCourses,
    marketplaceMarketingCourses,
    user
  )

  return (
    <div>
      {sortedInProgressSortables.length ? (
        <div className="my-8 flex flex-col space-y-4">
          <div className="mb-0 text-xl font-semibold text-rb-gray-400 md:mb-0">
            In Progress
          </div>
          {sortedInProgressSortables.map((sortable) =>
            renderSortable({
              sortable,
              legacyCoursesByProgramSlug,
              marketplaceCoursesByCourseSanityId,
              user
            })
          )}
        </div>
      ) : null}

      {sortedCompletedSortables.length ? (
        <div className="my-8 flex flex-col space-y-4">
          <div className="mb-0 text-xl font-semibold text-rb-gray-400 md:mb-0">
            Complete
          </div>
          {sortedCompletedSortables.map((sortable) =>
            renderSortable({
              sortable,
              legacyCoursesByProgramSlug,
              marketplaceCoursesByCourseSanityId,
              user
            })
          )}
        </div>
      ) : null}
    </div>
  )
}

const MyCoursesTab = () => {
  const { data: myCoursesData, loading: loadingMyCourses } = useMyCoursesQuery()

  if (loadingMyCourses) {
    return <Loading />
  }

  if (!myCoursesData?.currentUser?.hasContentInProgressOrComplete) {
    return (
      <div className="flex flex-col w-full h-[400px] items-center justify-center bg-rb-gray-50 mt-10 rounded">
        <RfHeader2>You haven&apos;t started any courses... yet</RfHeader2>
        <RfHeader3 className="text-rb-gray-300">
          Go at your own pace or join an upcoming live session
        </RfHeader3>
        <Button href={COURSES_ON_DEMAND_PATH} variant="fill" color="teal" size="large">
          Explore all courses
        </Button>
      </div>
    )
  }

  return (
    myCoursesData?.currentUser &&
    myCoursesData?.currentUser?.hasContentInProgressOrComplete && (
      <MyCourses
        user={myCoursesData.currentUser}
        loading={loadingMyCourses}
        userCourses={myCoursesData.currentUser.userCourses}
        userCohorts={myCoursesData.currentUser.cohorts}
        userProgramsInProgress={myCoursesData.currentUser.userProgramsProgressStarted}
        legacyMarketingCourses={myCoursesData?.legacyCourses}
        marketplaceMarketingCourses={myCoursesData?.marketplaceCourses}
        inProgressCourses={myCoursesData.currentUser.inProgressCourses}
      />
    )
  )
}

export default MyCoursesTab
