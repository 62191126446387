import { ReactNode, RefObject, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import Progress from 'components/Progress'
import { CloseIcon } from 'components/icons'

import useLocalStorage from 'hooks/useLocalStorage'

interface ChecklistOverlayProps {
  checklistName: string
  title: string
  className?: string
  checklistTasks: {
    row: ReactNode
    isCompleted: boolean
  }[]
  onCloseClick: () => void
  completionView?: ReactNode
  openButtonRef: RefObject<HTMLDivElement> | null
}

export const ChecklistOverlay = ({
  checklistName,
  title,
  checklistTasks,
  className,
  onCloseClick,
  completionView,
  openButtonRef
}: ChecklistOverlayProps) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  const [hasShownCompleted, setHasShownCompleted] = useLocalStorage(
    `has-shown-${checklistName}-completed`,
    false
  )

  useEffect(() => {
    // Function to handle click events
    const handleClickOutside = (event: any) => {
      // If the user clicks outside the overlay, close it. BUT...
      // If there's an open button ref provided, make sure the click was not on that button
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target) &&
        (!openButtonRef?.current || !openButtonRef.current.contains(event.target))
      ) {
        onCloseClick()
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside)

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onCloseClick, openButtonRef])

  if (!checklistTasks || checklistTasks.length < 1) {
    return null
  }

  const onClose = () => {
    onCloseClick()
  }

  const percentComplete =
    (checklistTasks.filter((task) => task.isCompleted).length / checklistTasks.length) *
    100

  if (!hasShownCompleted && percentComplete >= 100) {
    setHasShownCompleted(true)
  }

  return (
    <div ref={overlayRef} className="absolute top-12 right-0">
      <div
        className={twMerge(
          'relative w-full h-full z-100 border border-rb-gray rounded-lg shadow-coaching-input p-2 bg-rb-white',
          className
        )}
      >
        <button onClick={onClose} className="absolute top-4 right-4">
          <CloseIcon className="h-5 w-5 stroke-rb-gray-300" />
        </button>

        {percentComplete >= 100 && completionView && !hasShownCompleted ? (
          completionView
        ) : (
          <>
            <div className="p-4">
              <h3 className="font-medium text-rb-gray-400">{title}</h3>
              <Progress
                classNames="h-4 rounded-full bg-rb-info-50"
                progressBarClasses={{
                  progress: 'bg-rb-info rounded-full',
                  background: 'bg-rb-info-50'
                }}
                percentComplete={percentComplete}
              />
            </div>
            {checklistTasks.map((checklistTask) => checklistTask.row)}
          </>
        )}
      </div>
    </div>
  )
}
