import React, { useCallback, useEffect, useRef, useState } from 'react'

import AccessibleCheckbox from 'components/AccessibleCheckbox/AccessibleCheckbox'
import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemHeader
} from 'components/AccordionView'
import Button from 'components/Button'

import { MIN_WIDTH_TAILWIND_MD } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { ReactComponent as FilledTriangleDown } from 'images/icon--filled-triangle-down.svg'
import { ReactComponent as FilledTriangleUp } from 'images/icon--filled-triangle-up.svg'

export type ColumnConfigType<T extends object> = {
  /* reference needed for finding the column that's being sorted with */
  id: string
  /* for header rendering for columns at the top of the table */
  header: string
  /* needed for column data in a row, even if relying on the more flexible render function below. Can be used for column data rendering
   * on its own without the render function - the render function just provides more flexibility if needed. */
  accessor: string
  sortable?: boolean
  /* another way to render column data in a row, more control than just the accessor, but an accessor is still needed to provide the value param in this function */
  render?: (value: any, item: T) => React.ReactNode
  /* can be used for a custom sort function, especially helpful for expected behavior when we are using the render function to render column data instead of
   * the accessor */
  sortFunction?: (a: T, b: T) => number

  /* function to generate a data-test for each row, with access to the item */
  rowDataTestId?: (item: T) => string
  /* function to generate a data-id for each row, with access to the item */
  rowDataId?: (item: T) => string
  /* function to determine whether the checkbox should be hidden for a given item */
  hideCheckbox?: (item: T) => boolean
}

export type AccordionConfigType<T extends object> = {
  hideCheckbox?: (item: T) => boolean
  header: (item: T) => React.ReactNode
  fields: Array<{
    label: string
    value: string | (() => React.ReactNode) | ((item: T) => React.ReactNode)
  }>
}

interface SortIconProps {
  sortField: string
  columnId: string
  sortOrder: 'asc' | 'desc'
}

const SortIcon: React.FC<SortIconProps> = ({ sortField, columnId, sortOrder }) => {
  const isActive = sortField === columnId
  const activeColor = 'text-gray-900'
  const inactiveColor = 'text-[#B5B8B4]'

  return (
    <>
      <FilledTriangleUp
        data-testid={
          isActive && sortOrder === 'asc' ? 'active-triangle' : 'inactive-triangle'
        }
        className={`w-6 h-6 ${isActive && sortOrder === 'asc' ? activeColor : inactiveColor}`}
      />
      <FilledTriangleDown
        data-testid={
          isActive && sortOrder === 'desc' ? 'active-triangle' : 'inactive-triangle'
        }
        className={`w-6 h-6 -mt-4 ${isActive && sortOrder === 'desc' ? activeColor : inactiveColor}`}
      />
    </>
  )
}

// Utility function for column content rendering
const renderColumnContent = <T extends object>(
  column: ColumnConfigType<T>,
  item: T
): React.ReactNode => {
  const value = column.accessor ? getNestedValue(item, column.accessor) : undefined

  if (column.render) {
    return column.render(value, item)
  }

  // Handle null or undefined values
  if (value === null || value === undefined) {
    return <span className="text-sm">-</span>
  }

  // return value

  return <span className="text-sm">{value.toString()}</span>
}
const getNestedValue = (obj: Record<string, any>, path: string): any => {
  return path
    .split('.')
    .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj)
}

// Checkbox component
const TableCheckbox = <T extends object>({
  item,
  checkboxAccessor,
  selectedItems = [],
  handleCheckboxChange,
  children
}: {
  item: T
  checkboxAccessor: (item: T) => string
  selectedItems: string[]
  handleCheckboxChange: (item: T) => void
  children?: React.ReactNode
}) => (
  <div className="flex items-center ml-2 md:ml-0">
    <AccessibleCheckbox
      checked={selectedItems.includes(checkboxAccessor(item))}
      onChange={() => handleCheckboxChange(item)}
      screenReaderLabel={`Select ${checkboxAccessor(item)}`}
    />
    {children}
  </div>
)

// Content edges visibility utility function
const updateContentEdgesVisibility = (tableContainer: HTMLDivElement) => {
  const { scrollLeft, scrollWidth, clientWidth } = tableContainer
  const isAtStart = scrollLeft === 0
  const isAtEnd = Math.abs(scrollLeft + clientWidth - scrollWidth) < 1

  return {
    leftContentCutOff: !isAtStart,
    rightContentCutOff: !isAtEnd && scrollWidth > clientWidth
  }
}

const AccordionContent = <T extends object>({
  item,
  fields
}: {
  item: T
  fields: Array<{
    label: string
    value: string | (() => React.ReactNode) | ((item: T) => React.ReactNode)
  }>
}) => {
  return (
    <div className="grid grid-cols-1 gap-4">
      {fields.map(({ label, value }) => {
        return (
          <div key={label}>
            <div className="font-medium">{label}</div>
            <>
              {typeof value === 'function'
                ? value(item)
                : React.isValidElement(value)
                  ? value
                  : item[value as keyof T]}
            </>
          </div>
        )
      })}
    </div>
  )
}

export const useFilteredSearchedSortedData = <T extends object>(
  data: T[],
  searchTerm: string,
  filters: Record<string, string[]>,
  searchFields: string[] = [],
  sortField: string,
  sortOrder: 'asc' | 'desc',
  columns: ColumnConfigType<T>[],
  customFilters?: Partial<Record<string, (item: T, filterValues: string[]) => boolean>>
): T[] => {
  let processedData = data

  // Apply search filter
  if (searchTerm) {
    const lowercasedSearchTerm = searchTerm.toLowerCase()
    processedData = processedData.filter((item) =>
      searchFields.some((field) => {
        const value = getNestedValue(item, field)
        return value && String(value).toLowerCase().includes(lowercasedSearchTerm)
      })
    )
  }

  // Apply custom filters
  Object.entries(filters).forEach(([key, filterValues]) => {
    if (filterValues.length > 0) {
      processedData = processedData.filter((item) => {
        if (customFilters && key in customFilters) {
          const customFilter = customFilters[key]
          return typeof customFilter === 'function' && customFilter(item, filterValues)
        }
        const itemValue = getNestedValue(item, key)
        return filterValues.some((value) => {
          if (typeof itemValue === 'boolean') {
            return itemValue === (value.toLowerCase() === 'true')
          }
          return String(itemValue).toLowerCase().includes(value.toLowerCase())
        })
      })
    }
  })

  const sortData = (
    data: T[],
    sortField: string,
    sortOrder: 'asc' | 'desc',
    columns: ColumnConfigType<T>[]
  ) => {
    const column = columns.find((col) => col.id === sortField)
    if (!column) return data

    const getValue = (item: T) =>
      column.accessor ? getNestedValue(item, column.accessor) : null

    const allValues = data.map(getValue)

    const allValuesSame = allValues.every((val) => val === allValues[0])

    if (allValuesSame) return data

    return [...data].sort((a, b) => {
      if (column.sortFunction) {
        return sortOrder === 'asc' ? column.sortFunction(a, b) : column.sortFunction(b, a)
      }

      const aValue = getValue(a)
      const bValue = getValue(b)

      if (aValue === bValue) return 0
      if (aValue === null || aValue === undefined) return 1
      if (bValue === null || bValue === undefined) return -1

      return sortOrder === 'asc' ? (aValue < bValue ? -1 : 1) : aValue > bValue ? -1 : 1
    })
  }

  // After filtering, sort the data
  processedData = sortData(processedData, sortField, sortOrder, columns)

  return processedData
}

export const TableComponent = <T extends object>({
  data,
  columns,
  selectedItems = [],
  sortField,
  sortOrder,
  accordionData,
  checkboxAccessor,
  handleCheckboxChange,
  setSortField,
  setSortOrder,
  hideAllCheckboxes = false,
  tableDataTestId,
  tableRef
}: {
  data: T[]
  columns: ColumnConfigType<T>[]
  selectedItems?: string[]
  sortField: string
  sortOrder: 'asc' | 'desc'
  accordionData: AccordionConfigType<T>
  checkboxAccessor: (item: T) => string
  handleCheckboxChange?: (item: T) => void
  setSortField: (field: string) => void
  setSortOrder: (order: 'asc' | 'desc') => void
  hideAllCheckboxes?: boolean
  tableDataTestId?: string
  tableRef?: React.RefObject<HTMLTableElement>
}) => {
  const isAccordionView = useMediaQuery(`(max-width: ${MIN_WIDTH_TAILWIND_MD})`)
  const [openAccordions, setOpenAccordions] = useState<number[]>([])
  const [leftContentCutOff, setLeftContentCutOff] = useState(false)
  const [rightContentCutOff, setRightContentCutOff] = useState(true)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!isAccordionView) {
      // Reset content edges visibility when switching to table view
      if (tableContainerRef.current) {
        const { scrollWidth, clientWidth } = tableContainerRef.current
        setLeftContentCutOff(false)
        setRightContentCutOff(scrollWidth > clientWidth)
      }
    }
  }, [isAccordionView])

  const setupColumnVisibilityObserver = useCallback(() => {
    if (!tableContainerRef.current || isAccordionView) return () => {}

    const firstColumn = tableContainerRef.current.querySelector('th:first-child')
    const lastColumn = tableContainerRef.current.querySelector('th:last-child')
    if (!firstColumn || !lastColumn) return () => {}

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === firstColumn) {
            setLeftContentCutOff(!entry.isIntersecting)
          } else if (entry.target === lastColumn) {
            setRightContentCutOff(!entry.isIntersecting)
          }
        })
      },
      { threshold: 1, root: tableContainerRef.current }
    )

    observer.observe(firstColumn)
    observer.observe(lastColumn)

    const handleScroll = () => {
      if (tableContainerRef.current) {
        const { leftContentCutOff, rightContentCutOff } = updateContentEdgesVisibility(
          tableContainerRef.current
        )
        setLeftContentCutOff(leftContentCutOff)
        setRightContentCutOff(rightContentCutOff)
      }
    }

    tableContainerRef.current.addEventListener('scroll', handleScroll)

    // Initial check
    handleScroll()

    return () => {
      observer.disconnect()
      tableContainerRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [isAccordionView])

  useEffect(() => {
    return setupColumnVisibilityObserver()
  }, [setupColumnVisibilityObserver])

  return isAccordionView ? (
    <div>
      {data.map((item, index) => (
        <AccordionItem key={index}>
          <AccordionItemHeader
            id={`accordion-header-${index + 1}`}
            dataTestId={`accordion-header-${index}`}
            isOpen={openAccordions.includes(index)}
            onToggle={() => {
              setOpenAccordions((prev) =>
                prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
              )
            }}
          >
            {!hideAllCheckboxes &&
            (!accordionData.hideCheckbox || !accordionData.hideCheckbox(item)) &&
            handleCheckboxChange ? (
              <TableCheckbox
                item={item}
                checkboxAccessor={checkboxAccessor}
                selectedItems={selectedItems}
                handleCheckboxChange={handleCheckboxChange}
              >
                <div>{accordionData.header(item)}</div>
              </TableCheckbox>
            ) : (
              <div className={`${hideAllCheckboxes ? 'ml-2' : 'ml-3'}`}>
                {accordionData.header(item)}
              </div>
            )}
          </AccordionItemHeader>
          <AccordionItemContent
            id={`accordion-content-${index + 1}`}
            isOpen={openAccordions.includes(index)}
          >
            <div className="p-4 bg-white">
              <AccordionContent item={item} fields={accordionData.fields} />
            </div>
          </AccordionItemContent>
        </AccordionItem>
      ))}
    </div>
  ) : (
    <div className="relative overflow-hidden">
      <div
        data-testid="table-container"
        className={`relative overflow-x-auto overflow-y-auto max-h-[600px] ${
          !isAccordionView
            ? `rounded-lg border border-gray-200 ${
                rightContentCutOff ? 'border-r-transparent' : ''
              } ${leftContentCutOff ? 'border-l-transparent' : ''}`
            : ''
        }`}
        ref={tableContainerRef}
      >
        <div className="block">
          <table
            className="min-w-full divide-y divide-gray-200"
            data-test={tableDataTestId || 'data-table'}
            ref={tableRef}
          >
            <thead className="sticky top-0 z-10 bg-white">
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.header}
                    className="px-4 py-2 text-left text-med font-semibold text-[rgb(45,47,47)] whitespace-nowrap max-w-max"
                    aria-sort={
                      sortField === column.id
                        ? sortOrder === 'asc'
                          ? 'ascending'
                          : 'descending'
                        : 'none'
                    }
                  >
                    <div className="flex items-center">
                      <span className="mr-1">{column.header}</span>
                      {column.sortable && (
                        <Button
                          variant="text-only"
                          size="x-small"
                          className="flex-col"
                          onClick={() => {
                            setSortField(column.id)
                            setSortOrder(
                              sortOrder === 'asc' && sortField === column.id
                                ? 'desc'
                                : 'asc'
                            )
                          }}
                          aria-label={`Sort by ${column.header}, currently ${
                            sortField === column.id
                              ? sortOrder === 'asc'
                                ? 'ascending'
                                : 'descending'
                              : 'unsorted'
                          }`}
                        >
                          <SortIcon
                            sortField={sortField}
                            columnId={column.id}
                            sortOrder={sortOrder}
                          />
                        </Button>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
              <tr role="presentation" aria-hidden="true">
                <th colSpan={columns.length} className="p-0">
                  <div className="h-px bg-gray-200"></div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 [border-top:none]">
              {data.map((item, index) => (
                <tr
                  key={index}
                  data-test={
                    columns[0].rowDataTestId
                      ? columns[0].rowDataTestId(item)
                      : `table-row-${index}`
                  }
                  data-id={
                    columns[0].rowDataId ? columns[0].rowDataId(item) : 'row-data-id'
                  }
                >
                  {columns.map((column, columnIndex) => (
                    <td
                      key={column.header}
                      className={`px-4 py-2 whitespace-nowrap ${columnIndex === 0 ? 'flex items-center' : ''}`}
                    >
                      <div className="max-w-max">
                        {columnIndex === 0 ? (
                          <>
                            {!hideAllCheckboxes &&
                            (!column.hideCheckbox || !column.hideCheckbox(item)) &&
                            handleCheckboxChange ? (
                              <TableCheckbox
                                item={item}
                                checkboxAccessor={checkboxAccessor}
                                selectedItems={selectedItems}
                                handleCheckboxChange={handleCheckboxChange}
                              >
                                <div className="ml-4">
                                  {renderColumnContent(column, item)}
                                </div>
                              </TableCheckbox>
                            ) : !hideAllCheckboxes ? (
                              <div className="ml-[50px]">
                                {renderColumnContent(column, item)}
                              </div>
                            ) : (
                              renderColumnContent(column, item)
                            )}
                          </>
                        ) : (
                          renderColumnContent(column, item)
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
