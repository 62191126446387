import { useRef, useState } from 'react'

import { LearningGoalSessionData } from 'pages/goals/type'

import Button from 'components/Button'
import Dropdown from 'components/forms/Dropdown'

import { useQueryParams } from 'hooks/useQueryParams'

import { ReactComponent as LockIcon } from 'images/icon--lock.svg'

import { SimpleProgressBar } from '../ProgressBar'

const TeamLearningTime = ({
  activeSession
}: {
  activeSession: LearningGoalSessionData | null
}) => {
  const { setNewParams } = useQueryParams()
  const [startRange, setStartRange] = useState(
    localStorage.getItem('goal-target-start') || '30'
  )
  const [endRange, setEndRange] = useState(
    localStorage.getItem('goal-target-end') || '240'
  )
  const [checkin, setCheckin] = useState(localStorage.getItem('goal-checkin') || '')
  const [dueDate, setDueDate] = useState(localStorage.getItem('goal-due-date') || '')

  const dateInputRef = useRef<any>(null) // Specify the type as HTMLInputElement

  const handleDivClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker() // This triggers the date picker
    }
  }
  const handleContinue = () => {
    setNewParams({ step: 'assign-goals', sessionId: activeSession!.externalId })
  }

  const MINUTES_SELECTION = [
    { label: '30 minutes', id: '30' },
    { label: '60 minutes', id: '60' },
    { label: '2 hours', id: '120' },
    { label: '3 hours', id: '180' },
    { label: '4 hours', id: '240' }
  ]
  const dropdownOptions = () => {
    return [
      {
        label: 'Weekly check-ins',
        id: 'Weekly check-ins'
      },
      {
        label: 'Monthly check-ins',
        id: 'Monthly check-ins'
      },
      {
        label: 'Quarterly check-ins',
        id: 'Quarterly check-ins'
      }
    ]
  }
  const handleStart = (time: string) => {
    setStartRange(time)
  }
  const handleEnd = (time: string) => {
    setEndRange(time)
  }

  return (
    <div className="flex bg-white w-full justify-center">
      <div className="max-w-2xl">
        <div className="text-[28px] font-medium mb-2">
          {"Set up your team's learning time"}
        </div>
        <div className="font-normal text-rb-gray-300 mb-6">
          Teams that block time are more successful in meeting their goals
        </div>
        <div className="flex flex-col rounded-xl w-full">
          <div className="font-sans text-rb-gray-400 font-semibold mb-2">
            {
              "What's the amount of time you'd like your team to focus on learning each week?"
            }
          </div>

          <div className="flex items-center justify-start">
            <Dropdown
              options={MINUTES_SELECTION}
              buttonClassName="rounded-md w-[315px]"
              placeholder="Select experience level"
              selection={startRange}
              setSelection={(value = '') => {
                handleStart(value as string)
                localStorage.set('goal-target-start', value as string)
              }}
            />
            <div className="mx-4">to</div>
            <Dropdown
              options={MINUTES_SELECTION}
              buttonClassName="rounded-md w-[315px]"
              placeholder="Select experience level"
              selection={endRange}
              setSelection={(value = '') => {
                handleEnd(value as string)
                localStorage.setItem('goal-target-end', value as string)
              }}
            />
          </div>
        </div>

        <div className="flex flex-col rounded-xl w-full my-8">
          <div className="font-sans text-rb-black font-semibold mb-2">
            How often would you like to check-in on goal progress?
          </div>
          <div className="font-normal text-rb-gray-300 text-sm mb-4">
            Check-ins include a report on team progress and new goal recommendations{' '}
          </div>

          <Dropdown
            options={dropdownOptions()}
            buttonClassName="rounded-md"
            placeholder="Select experience level"
            selection={checkin}
            setSelection={(value = '') => {
              setCheckin(value as string)
              localStorage.setItem('goal-checkin', value as string)
            }}
          />
        </div>

        <div className="flex flex-col rounded-xl w-full my-8">
          <div className="font-sans text-rb-black font-semibold mb-2">
            Add a due date for this goal:
          </div>

          <div
            className="mt-2 flex h-10 items-center justify-between rounded-md border border-rb-gray-100 px-2.5 text-sm"
            onClick={handleDivClick}
          >
            <input
              ref={dateInputRef}
              className="w-full -z-0 outline-none"
              type="date"
              value={dueDate}
              onChange={({ target }) => {
                setDueDate(target.value)
                localStorage.setItem('goal-due-date', target.value as string)
              }}
            />
          </div>
        </div>

        <div className="mb-10 flex justify-between items-center">
          <SimpleProgressBar totalSteps={2} completed={1} />
          <div className="flex">
            <Button
              className="h-10 mr-1"
              variant="text-only"
              size="small"
              onClick={() => {
                setNewParams({
                  step: 'recommendations',
                  sessionId: activeSession!.externalId
                })
              }}
            >
              Back
            </Button>
            <Button
              className="h-10"
              size="small"
              disabled={!checkin || !endRange || !startRange || !dueDate}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>
        <div className="flex space-x-5 text-rb-gray-250">
          <LockIcon className="mr-3 mx-1 w-2.5 self-center text-rb-gray-250" />
          We keep your responses confidential and your information is never shared
        </div>
      </div>
    </div>
  )
}

export default TeamLearningTime
